<template>
  <div>
    <strong>{{config.header}}</strong> ({{config.data.length}} {{config.headerRecord}})
    <div :id="id" :style="`width: ${config.width}; height: ${config.height};`"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['config'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      console.log(this.config.data)
      const series = _.initial(_.keys(this.config.data[0])).map(() => {
        return { type: 'bar', stack: 'total' }
      })
      const options = {
        legend: {},
        tooltip: {},
        grid: {
          bottom: 400
        },
        dataset: {
          source: this.config.data
        },
        xAxis: {
          axisLabel: {
            rotate: 90
          },
          type: 'category'
        },
        yAxis: {},
        series: series
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(options)
    },
    reload: function () {
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'config': 'reload'
  }
}
</script>

<style>
</style>
