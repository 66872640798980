<template>
  <div>
    <strong>{{config.header}}</strong> ({{config.data.length}} {{config.headerRecord}})
    <div :id="id" :style="`width: ${config.width}; height: ${config.height};`"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['config'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      const values = []
      const labels = []
      _.each(this.config.data, x => {
        values.push({ value: x.value, label: { position: 'left' } })
        labels.push(x.name)
      })
      const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '50%'
        },
        xAxis: {
          type: 'value',
          show: false,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: labels
        },
        series: [
          {
            name: this.config.name,
            type: 'bar',
            stack: this.config.name,
            label: {
              show: true,
              formatter: '{b}'
            },
            data: values
          }
        ]
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(options)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'config': 'reload'
  }
}
</script>

<style>
</style>
