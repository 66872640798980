<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <p><b-link :to="{ name: 'Processes' }">all Processes</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'process' }}">Edit</b-button>
                  {{item.name}}
                </h2>
                <h3 v-if="item.description">Description</h3>
                <div v-if="item.description" v-html="item.description"></div>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="item.parent">
                <div>
                  <h3>Parent process</h3>
                  <div>
                    <router-link :to="{ name: 'Process', params: { id: item.parent.id }}"><h5>{{item.parent.number}} - {{item.parent.name}}</h5></router-link>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div v-if="item.children.length > 0">
                  <h3>Child processes</h3>
                  <div v-for="child in item.children" :key="'processchild'+child.id">
                    <router-link :to="{ name: 'Process', params: { id: child.id }}"><h5>{{child.number}} - {{child.name}}</h5></router-link>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-5">
                  <delete-standard
                    v-if="permission.delete"
                    :id="item.id"
                    model="process"
                  ></delete-standard>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import DeleteStandard from '@/components/DeleteStandard'

export default {
  name: 'Process',
  components: {
    DeleteStandard
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission.read = ac.can(this.user.acgroups).readAny('process').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('process').granted
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('process').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      moment: moment,
      permission: {
        delete: false,
        edit: false,
        read: false
      },
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.$stat.log({ page: 'process', action: 'open process', model: 'process', model_id: this.$route.params.id })
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/process/item/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        console.log(this.item)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  },
  watch: {
    '$route.params.id': function () {
      this.load()
    }
  }
}
</script>

<style scoped>
h3 {
  margin-top: 1.5em;
}
h4 {
  margin-top: 1.5em;
}
</style>
